import {
    resizeFuncCheck,
    resizeFunc,
    resizecheck
} from './_resizecheck';

var gNavBtn, globalNav, scrollpos, sc;
globalNav = '#globalNav';
gNavBtn = '#gNavBtn';
export function headerFunc() {
    $(gNavBtn).on('click', function () {

        $(this).find('a').toggleClass('active');
        if ($('#trigger').hasClass('active')) {
            $(globalNav).stop().slideDown(500);
            scrollpos = $(window).scrollTop();
            $('body').addClass('fixedBody').css({
                'top': -scrollpos
            });
        } else {
            $(globalNav).stop().slideUp(500);
            $('body').removeClass('fixedBody').css({
                'top': 0
            });
            window.scrollTo(0, scrollpos);
        }
    });
}
var scrolled, sectionTopArr, a,setH;
export var ankerGnav, nowPosition, nowScroll, floorLayoutWrap, wH, sectionPos, navList, sectionTopArr, current, margin, changeNavCurrent, curNum2,setReset,resizechecks;
sectionTopArr = new Array;
current = -1;
export function headercurrentFunc() {
    //現在のスクロール量
    nowScroll = $(window).scrollTop();
    //レスポンシブチェック
    resizechecks = $('#responsibleCheck').width();
    
    if(resizechecks !=2){
        setH = 89;
    }else{
        setH = 55;
    }
    
    //(1)各sectionの縦位置を取得
    $('#idx').find('.scAnk').each(function (i) {
        sectionTopArr[i] = $(this).offset().top - setH;
    });
    //console.log('配列に入ってる距離は:'+sectionTopArr);

    //(2)各セクションの位置とスクロール位置を比較して、条件にあったらchangeNavCurrentを実行
    for (var i = sectionTopArr.length - 1; i >= 0; i--) {
        if (nowScroll > sectionTopArr[i] - 10) {
            changeNavCurrent(i);
            break;
        }else{
            changeNavCurrent('-1');
        }
    }
    //(3)ナビの処理
    function changeNavCurrent(curNum) {
        if (curNum != current) {
            current = curNum;
            curNum = curNum + 2;
            $('#globalNav li').removeClass('current');
            $('#globalNav li:nth-child(' + curNum + ')').addClass('current');
        }else{
            $('#globalNav li').eq(0).addClass('current');
        }
    }
}
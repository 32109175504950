
/* ----------------------------------------------------------------------------------
import読み込み
---------------------------------------------------------------------------------- */
import {urlHashFunc} from './common/_common';
import {hoverFunc,telLinkFunc,uaHoverFunc} from './common/_hoverFunc';
import {headerFunc,headercurrentFunc} from './common/_header';
import {resizeFuncCheck,resizeFunc} from './common/_resizecheck';
import {heightLine,heightLineSetFunc,heightLineFunc} from './common/_heightLine';
import {pageLinkAnimationFunc,pageLinkNavFunc} from './common/_pageLinkAnimation';
import {indexFunc} from './pages/_index';
import {contactAjaxFunc} from './common/_ajaxContact';
/* ----------------------------------------------------------------------------------
通常実行
---------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------
window実行
---------------------------------------------------------------------------------- */
// ロード時
$(window).on('load', function() {
    heightLine();
    heightLineSetFunc();
    indexFunc();
    headerFunc();
    pageLinkAnimationFunc();
    pageLinkNavFunc();
    urlHashFunc();
    contactAjaxFunc();
});

// リサイズ時
$(window).on('resize', function() {
    heightLineFunc();
    pageLinkAnimationFunc();
});

// スクロール時
$(window).on('scroll', function() {
    
});

$(window).on('load scroll resize',function(){
    headercurrentFunc();
});


/* ----------------------------------------------------------------------------------
ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
---------------------------------------------------------------------------------- */
var uaFlag = 0;
if ((
	navigator.userAgent.indexOf('iPhone') > 0)
	|| (navigator.userAgent.indexOf('iPod') > 0)
	|| (navigator.userAgent.indexOf('iPad') > 0)
	|| (navigator.userAgent.indexOf('Android') > 0)
	|| (navigator.userAgent.indexOf('BlackBerry') > 0)
	|| (navigator.userAgent.indexOf('PlayBook') > 0)
	|| (navigator.userAgent.indexOf('Kindle') > 0)
	|| (navigator.userAgent.indexOf('Silk') > 0)
	|| (navigator.userAgent.indexOf('Windows Phone') > 0)
	|| (navigator.userAgent.indexOf('Nokia') > 0)
) {
	// スマホ・タブレット時の処理
	uaFlag = 1;
    uaHoverFunc();
} else {
	// PC時の処理
	uaFlag = 3;
    hoverFunc();
    telLinkFunc();
}


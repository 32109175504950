import {
    resizeFuncCheck,
    resizeFunc,
    resizecheck
} from '../common/_resizecheck';
export function urlHashFunc() {
    var urlHash, target, position;
    //URLのハッシュ値を取得
    urlHash = location.hash;
    //ハッシュ値があればページ内スクロール
    if (urlHash) {
        //スクロールを0に戻す
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
            //ロード時の処理を待ち、時間差でスクロール実行
            scrollToAnker(urlHash);
        }, 100);
    }
    // 指定したアンカー(#ID)へアニメーションでスクロール
    function scrollToAnker(hash) {
        target = $(hash);
        resizeFuncCheck();
        resizeFunc();
        if(resizecheck !=2 ){
            position = target.offset().top;
        }else{
            position = target.offset().top - 55;
        }
        $('body,html').animate({
            scrollTop: position
        }, 0, 'easeInOutQuart');
        return false;
    }
}
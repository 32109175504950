// jQueryプラグイン
import 'jquery.easing';
/* ----------------------------------------------------------------------------------
ページ内リンクのアニメーション
---------------------------------------------------------------------------------- */
export function pageLinkAnimationFunc() {
}

export function pageLinkNavFunc() {
    var jsM, href, positionM, target, setPos, setScr, scrollpos;
    jsM = '.jsM';
    $(jsM).on('click', function () {
        $(globalNav).find('ul li').removeClass('current');
        $(this).parent().addClass('current');
        scrollpos = $(window).scrollTop();
        $('body').removeClass('fixedBody').css({
            'top': 0
        });
        window.scrollTo(150, scrollpos);

        $(gNavBtn).find('a').removeClass('active');
        $(globalNav).stop().slideUp(500).find('ul').scrollTop(0);

        href = $(this).attr("href");
        target = $(href == "#" || href == "" ? 'html' : href);
        positionM = target.offset().top;
        setPos = positionM - 55;

        $('body,html').animate({
            scrollTop: setPos
        }, 0, 'easeInOutQuart');
        return false;

    });
}
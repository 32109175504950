// jQueryプラグイン
import 'jquery.easing';
import {
    resizeFuncCheck,
    resizeFunc,
    resizecheck
} from '../common/_resizecheck';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

export function indexFunc() {
    var jsA, jsB, ankerJs, href, target, positionM, changeRadio;
    jsA = '#jsA,#spjsA';
    jsB = '#jsB,#spjsB';
    ankerJs = '#ankerJs';
    //プライバシーポリシー部分
    $(".infoBox").mCustomScrollbar();

    //種別の挙動
    $('input[type="radio"]').change(function () {
        changeRadio = $(this).val();
        if (changeRadio != 'お申込み') {
            $('#course').find('option').eq(0).prop('selected', 'selected');
            $("select[name='course']").prop('disabled', true);
        } else {
            $("select[name='course']").prop('disabled', false);
        }
    });

    //キービジュアルボタン(お問い合わせ選択時)
    $(jsA).on('click', function () {
        href = $(this).attr("href");
        target = $(href == "#" || href == "" ? 'html' : href);
        resizeFuncCheck();
        resizeFunc();
        if (resizecheck != 2) {
            positionM = target.offset().top;
        } else {
            positionM = target.offset().top - 55;
        }

        $('body,html').animate({
            scrollTop: positionM
        }, 800, 'easeInOutQuart');
        return false;
    });

    //キービジュアルボタン(減量プラン選択時)
    $(jsB).on('click', function () {
        $(ankerJs).find('label').last().find('input').prop('checked', true);
        $("select[name='course']").prop('disabled', true);
        href = $(this).attr("href");
        target = $(href == "#" || href == "" ? 'html' : href);
        resizeFuncCheck();
        resizeFunc();
        if (resizecheck != 2) {
            positionM = target.offset().top;
        } else {
            positionM = target.offset().top - 55;
        }
        $('body,html').animate({
            scrollTop: positionM
        }, 800, 'easeInOutQuart');
        return false;
    });
}
export var tableInfoObj, memoryObj;
export function contactAjaxFunc() {
    var contactAjax, resultArr, queryStr, returnObj, postData, form, confirmationTableFunc, tableColumn, counter, contactAjaxSend, returnEdit, cloneFlag, radikoFunc, changeRadio, changeRadioNow, formLayoutBlock, contactBtnLayout, contactFormColumn, thanksLayout, gRecaptchaBox, validationFunc, lastVerification;


    var counterA, counterB, counterC;
    //確認ボタン
    contactAjax = '#contactAjax';

    //戻って編集
    returnEdit = '#returnEdit';

    //送信ボタン
    contactAjaxSend = '#contactAjaxSend';
    form = '#myForm';
    tableColumn = '.tableColumn';
    formLayoutBlock = '.formLayoutBlock';
    contactBtnLayout = '.contactBtnLayout';
    contactFormColumn = '.contactFormColumn';
    thanksLayout = '.thanksLayout';
    gRecaptchaBox = '.gRecaptchaBox';

    returnObj = [];

    //Table情報を最初に配列に突っ込む
    tableInfoObj = [];
    $(tableColumn).find('td').each(function (i) {
        tableInfoObj.push($(this).html());
    });

    //リアルタイムチェック
    var nowTypeVal, nowCourseVal;
    validationFunc = function () {
        
        $("#course").change(function () {
            nowTypeVal = $("input[name='type']:checked").val();
            nowCourseVal = $('[name=course]').val();
            if (nowTypeVal == 'お申込み' && nowCourseVal == '未選択') {
                $(this).next('p.errorTxt').text('希望コースを選択してください。');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });

        $("input[name='email']").blur(function () {
            if (!$(this).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
                $(this).next('p.errorTxt').text('正しいメールアドレスを入力してください');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });

        $("input[name='tel']").blur(function () {
            if (!$(this).val().match(/^[0-9]+$/)) {
                $(this).next('p.errorTxt').text('数字のみでご入力してください。');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });
        $('input[type="text"]').blur(function () {
            if ($(this).val().match(/[<(.*)>.*<\/\1>]/)) {
                $(this).next('p.errorTxt').text('HTMLコードが含まれています。');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });
        $('textarea[name="friiArea"]').blur(function () {
            if ($(this).val().match(/[<(.*)>.*<\/\1>]/)) {
                $(this).next('p.errorTxt').text('HTMLコードが含まれています。');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });

        $("input[name='name']").blur(function () {
            if ($(this).val().match(/[<(.*)>.*<\/\1>]/)) {
                $(this).next('p.errorTxt').text('お名前を入力してください。');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });

        $('.verifName').blur(function () {
            if ($(this).val() == '') {
                $(this).next('p.errorTxt').text('お名前を入力してください。');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });

        $('.verifFuri').blur(function () {
            if ($(this).val() == '') {
                $(this).next('p.errorTxt').text('お名前のふりがなを入力してください。');
            } else {
                $(this).next('p.errorTxt').empty();
            }
        });

        //お問い合わせ・ご質問内容
        $('textarea').bind('keydown keyup keypress change', function () {
            var thisValueLength = $(this).val().length;
            $('#txtcount').html(thisValueLength);
        });
        
    }
    validationFunc();


    //ラジオボタンの挙動
    radikoFunc = function () {
        changeRadio = $("input[name='type']:checked").val();
        if (changeRadio != 'お申込み') {
            $("select[name='course']").prop('disabled', true);
        } else {
            $("select[name='course']").prop('disabled', false);
        }
        $('input[type="radio"]').change(function () {
            changeRadioNow = $("input[name='type']:checked").val();
            if (changeRadioNow != 'お申込み') {
                $('#course').find('option').eq(0).prop('selected', 'selected');
                $("select[name='course']").prop('disabled', true);
            } else {
                $("select[name='course']").prop('disabled', false);
            }
        });
    }

    //確認画面の出力
    confirmationTableFunc = function (returnObj) {
        //JSON形式で配列に追加
        returnObj = JSON.parse(returnObj);
        $.each(returnObj, function (i, value) {
            counterB++;
            $(tableColumn).find('td').eq(counterB).append('<p>' + value + '</p>' + '<input type="hidden" name="' + i + '" value="' + value + '">');
        });
        $(tableColumn).find('td').fadeIn();
    }

    //戻るボタンの処理(Tableに書き込み)
    var insertTable;
    insertTable = function () {
        $.when(
            //初期値のテーブルを書き込み
            $(tableColumn).find('td').each(function (i) {
                $(this).html(tableInfoObj[i])
            })
        ).done(function () {
            //テーブルに入力された情報を書き込み
            $.each(memoryObj, function (i, value) {
                counterA++;
                $(tableColumn).find('td').eq(counterA).find('.target').val(value);

                //種別の処理
                if (i == 'type') {
                    $('#ankerJs').find('label').each(function (i) {
                        $(this).find('input').prop('checked', false);
                        if ($(this).find('input').val() == value) {
                            $(this).find('input').prop('checked', true);
                        }
                    })
                }

                //コースの処理
                if (i == 'course') {
                    $('#course').find('option').each(function (i) {
                        if ($(this).val() == value) {
                            $(this).prop('selected', 'selected');
                        }
                    });
                }

                //フリーエリアの処理
                if (i == 'friiArea') {
                    $('textarea[name="friiArea"]').val(value);
                }
            });
            grecaptcha.reset();
            radikoFunc();
            validationFunc();
            $(formLayoutBlock).fadeIn();
            $(tableColumn).find('td').fadeIn();
            $(gRecaptchaBox).fadeIn();
        });
    }

    var nowRadio, nowCourse;
    //入力項目のチェック
    lastVerification = function () {
        nowRadio = $("input[name='type']:checked").val();
        nowCourse = $('[name=course]').val();
        if (nowRadio == 'お申込み' && nowCourse == '未選択') {
            $('#course').next('p.errorTxt').text('希望コースが選択されていません。');
            return false;
        } else if ($('input[name="name"]').val() == '') {
            $('.verifName').next('p.errorTxt').text('お名前を入力してください。');
            return false;
        } else if ($('input[name="furigana"]').val() == '') {
            $('.verifFuri').next('p.errorTxt').text('ふりがなを入力してください。');
            return false;
        } else if ($('input[name="email"]').val() == '') {
            $('input[name="email"]').next('p.errorTxt').text('メールアドレスを入力してください');
            return false;
        } else if ($('input[name="tel"]').val() == '') {
            $('input[name="tel"]').next('p.errorTxt').text('電話番号を入力してください。');
            return false;
        }
        //else if (!$('#agreement').prop('checked')) {
        //alert("個人情報取り扱いに同意していません。");
        //return false;
        else if (!$('#g-recaptcha-response').val()) {
            alert("個人情報取り扱いに同意していません。");
            $('.gRecaptchaBox').find('p.errorTxt').text('個人情報取り扱いに同意していません。');
            return false;
        } else {
            return true;
        }
    }

    //自動スクロール処理
    var errorScrollFunc, scrollSet;
    errorScrollFunc = function () {
        scrollSet = $('table.tableColumn').offset().top;
        $('body,html').animate({
            scrollTop: scrollSet
        }, 800, 'easeInOutQuart');
        return false;
    }

    //確認ボタンの処理
    $(contactAjax).click(function () {
        var Velflag;
        if (lastVerification()) {
            Velflag = true;
            $("select[name='course']").prop('disabled', false);
        } else {
            Velflag = false;
            errorScrollFunc();
        }
        if (Velflag) {
            /*変数をリセット*/
            counterA = -1;
            counterB = -1;
            counterC = -1;
            memoryObj = [];
            queryStr = $(form).serialize();
            //TDの中身を消去
            $(tableColumn).find('td').fadeOut(500).empty();
            $('.jsBtnA,.jsBtnB').removeClass('notActive').addClass('active');
            $(contactBtnLayout).addClass('active');
            $(formLayoutBlock).fadeOut();
            $(gRecaptchaBox).fadeOut();
             $('.gRecaptchaBox').find('p.errorTxt').empty();
            postData = {
                'act': 'confirmation',
                'queryStr': queryStr,
                'g-recaptcha-response': $('#g-recaptcha-response').val(),
            };
            $.ajax({
                type: 'POST',
                url: '/contact/settingContact.php',
                dataType: 'text',
                data: postData,
            }).done(function (data) {
                setTimeout(function () {
                    confirmationTableFunc(data);
                }, 500);
                memoryObj = JSON.parse(data);
                errorScrollFunc();

            }).fail(function (xhr, textStatus, errorThrown) {
                console.log("NG:" + xhr.status);
            });
        }
    });



    //戻って編集ボタンの処理
    $(returnEdit).click(function () {
        $(tableColumn).find('td').fadeOut(500).empty();
        $(contactBtnLayout).removeClass('active');
        $('.jsBtnB,.jsBtnA').removeClass('active').addClass('notActive');
        setTimeout(function () {
            insertTable();
        }, 500);
    });

    //送信ボタンの処理
    $(contactAjaxSend).click(function () {
        queryStr = $(form).serialize();
        postData = {
            'act': 'send',
            'queryStr': queryStr,
        };
        $.ajax({
            type: 'POST',
            url: '/contact/settingContact.php',
            dataType: 'text',
            data: postData,
        }).done(function (data) {
            $(contactFormColumn).fadeOut();
            $(thanksLayout).fadeIn();
        }).fail(function (xhr, textStatus, errorThrown) {
            console.log("NG:" + xhr.status);
        });
    });
}